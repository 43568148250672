import React, { useState, useEffect } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import authApi from "./auth-api";
import { userActions, apiList, methodType } from "./constant";
import getToken from "../util/tokenGenerator";
import { stripHtmlTags } from "../util/helper";

const AppStateContext = React.createContext();

const getData = async ({
  body = {},
  url = "",
  method = methodType.post,
  isWithCredentials = true,
  tokenhandler = () => null,
  isSecured = false,
}) => {
  let response = await authApi({
    body,
    method,
    url,
    isWithCredentials,
    tokenhandler,
    isSecured,
  });
  return response;
};

function AppStateProvider({ children }) {
  const [appState, setAppState] = useState({
    isError: false,
    isPopUpBlocked: window.sessionStorage.getItem("isPopUpBlocked") === "true",
  });
  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const origin = "settings";

  useEffect(async () => {
    if (isAuthenticated) {
      const myAccounts = instance.getAllAccounts();
      const accountObj = myAccounts[0] ?? {};
      const { idTokenClaims = {} } = accountObj;
      // todo enable after integeration
      const { uid = "", env = "qa", isMigrationFlow = false } = idTokenClaims;
      setAppState({
        ...appState,
        uid: uid,
        env: env,
        isMigrationFlow: isMigrationFlow,
        isLoading: true,
        isError: false,
        isMd2Lite: false,
        isUPApp: false,
        isObSetup: false,
      });
      if (uid) {
        await getUserData(uid);
      }
    }
  }, [isAuthenticated]);

  const getUserData = async (uid) => {
    //sync existing app lang
    const lang = window.localStorage.getItem("lang");
    await updateAppState({
      body: { preferred_language: lang },
      type: userActions.updateUserInfo,
      uid,
    });
    const url = apiList[userActions.fetchUserInfo];
    const response = await getData({
      body: { origin, user_id: uid },
      method: methodType.post,
      url,
      tokenhandler: () => getToken(instance, inProgress, accounts),
      isSecured: true,
    });

    const { user = "", success = false } = response?.data ?? {};
    if (success) {
      const {
        apps,
        first_name = "",
        last_name = "",
        mobile_phone = "",
        email = "",
        display_name = "",
        first_sign_in: firstSignIn = false,
        mfa = {},
        preferred_language = "en",
        contact_phone = "",
        contact_phone_ext = "",
        password_last_change_date = null,
        password_exp_date = null,
        questions_last_change_date = null,
        hasPartnerRole = null,
        hasSharedCashRole = null,
      } = user;
      window.localStorage.setItem(
        "lang",
        String(preferred_language).toLowerCase()
      );

      const isMd2LiteUserResponse = await getData({
        body: { user_id: uid, email: email },
        method: methodType.post,
        url: apiList[userActions.isMd2Lite],
        tokenhandler: () => getToken(instance, inProgress, accounts),
        isSecured: true,
      });

      const isObSetupResponse = await getData({
        body: { user_id: uid, email },
        method: methodType.post,
        url: apiList[userActions.isObSetup],
        tokenhandler: () => getToken(instance, inProgress, accounts),
        isSecured: true,
      });

      const isMd2LiteUser = isMd2LiteUserResponse?.data?.success ?? false;
      const isObSetupUser =
        (isObSetupResponse?.data?.success ?? false) &&
        (isObSetupResponse?.data?.isEligible ?? false);
      const hasMd2Tile = apps.reduce((prevVal, currentVal) => {
        const { app_code = "" } = currentVal;
        return prevVal || app_code?.toString().toLowerCase() === "md2";
      }, false);
      const { app_code = "" } = apps[0] ?? {};
      const searchParams = new URLSearchParams(window?.location?.search) ?? "";
      const logoutAction = searchParams?.get("logout") ?? "";
      const isUPInApps = apps.find(object => object.app_code === 'UP');
      // isRedirect is to determine uis user is landing on sso page
      // true means no sso landing page to show
      const isRedirect =
        isMd2LiteUser ||
        hasPartnerRole ||
        hasSharedCashRole || 
        isUPInApps ||
        (apps.length === 1 && app_code?.toString().toLowerCase() === "de") ||
        (isObSetupUser && hasMd2Tile) ||
        logoutAction === "true";

      setAppState({
        ...appState,
        uid: uid,
        isLoading: isRedirect,
        preferred_language,
        isMd2Lite: isMd2LiteUser,
        isUPApp: isUPInApps,
        isObSetup: isObSetupUser,
        profile: {
          firstName: stripHtmlTags(first_name),
          lastName: stripHtmlTags(last_name),
          displayName: display_name,
          businessPhone: contact_phone,
          userPhone: mobile_phone,
          userEmail: email,
          businessExtension: contact_phone_ext,
          firstSignIn,
          mfa: mfa,
          passLastChange: password_last_change_date,
          passExpiry: password_exp_date,
          secQuestionlastUpdate: questions_last_change_date,
        },
        apps,
        hasPartnerRole,
        hasSharedCashRole,
      });
    } else {
      setAppState({
        ...appState,
        uid: uid,
        isLoading: isRedirect,
      });
    }
  };

  const updateAppState = async (payload) => {
    const {
      type = "",
      body = {},
      uid: uidNew = "",
      postCallHandler = () => null,
    } = payload;
    const { uid = uidNew } = appState;
    let url = "";
    let response = {};
    switch (type) {
      case userActions.fetchUserInfo: {
        url = apiList[userActions.fetchUserInfo];
        response = await getData({
          body: { ...body, user_id: uid, origin },
          method: methodType.post,
          url,
          tokenhandler: () => getToken(instance, inProgress, accounts),
          isSecured: true,
        });
        const { user = "", success = false } = response?.data ?? {};
        if (success) {
          const {
            first_name = "",
            last_name = "",
            mobile_phone = "",
            email = "",
            display_name = "",
            mfa = {},
            preferred_language = "en",
            contact_phone = "",
            contact_phone_ext = "",
            password_last_change_date = null,
            password_exp_date = null,
            questions_last_change_date = null,
            hasPartnerRole,
            hasSharedCashRole,
          } = user;
          window.localStorage.setItem(
            "lang",
            String(preferred_language).toLowerCase()
          );
          setAppState({
            ...appState,
            preferred_language,
            isLoading: false,
            hasPartnerRole,
            hasSharedCashRole,
            profile: {
              firstName: stripHtmlTags(first_name),
              lastName: stripHtmlTags(last_name),
              displayName: display_name,
              businessPhone: contact_phone,
              userPhone: mobile_phone,
              userEmail: email,
              businessExtension: contact_phone_ext,
              mfa: mfa,
              passLastChange: password_last_change_date,
              passExpiry: password_exp_date,
              secQuestionlastUpdate: questions_last_change_date,
            },
          });
        } else {
          setAppState({
            ...appState,
            isLoading: false,
          });
        }
        break;
      }
      case userActions.getAppIds: {
        const myAccounts = instance.getAllAccounts() ?? [];
        const accountObj = myAccounts[0] ?? {};
        const { name = "" } = accountObj;
        setAppState({ ...appState, firstName: stripHtmlTags(name) });
        break;
      }
      case userActions.updateUserInfo: {
        url = apiList[userActions.updateUserInfo];
        updateAppStateLocal({ ...appState, isLoading: true });
        response = await getData({
          body: { ...body, user_id: uid, origin },
          method: methodType.post,
          url,
          tokenhandler: () => getToken(instance, inProgress, accounts),
          isSecured: true,
        });
        if (response.data.success) {
          console.log("lang synced successful");
        } else {
          console.log("lang sync unsuccessful");
        }

        break;
      }
      case userActions.middlewareLogout: {
        const url = apiList[userActions.middlewareLogout];
        const { email = "" } = body;
        setAppState({
          ...appState,
          isLoading: true,
        });
        response = await getData({
          body: {},
          method: methodType.get,
          url: `${url}?email=${email}`,
          isWithCredentials: false,
        });
        if (response.status === 200) {
          console.log("logout successful");
          postCallHandler();
          setAppState({
            ...appState,
            isLoading: false,
          });
        } else {
          console.log("error logging out user");
          postCallHandler();
          setAppState({
            ...appState,
            isLoading: false,
          });
        }

        break;
      }
      case userActions.ssoSessionCheck: {
        const url = apiList[userActions.ssoSessionCheck];
        const { email = "" } = body;
        setAppState({
          ...appState,
          isLoading: true,
        });
        response = await getData({
          body: {},
          method: methodType.get,
          url: `${url}?email=${email}&action=clear`,
          isWithCredentials: false,
        });
        if (response.status === 200) {
          console.log("sso logout successful");
          postCallHandler();
          setAppState({
            ...appState,
            isLoading: false,
          });
        } else {
          console.log("error logging out sso user");
          setAppState({
            ...appState,
            isLoading: false,
          });
        }

        break;
      }
      default:
        break;
    }
  };

  const updateAppStateLocal = (obj = null) =>
    setAppState((prevAppState) => ({ ...prevAppState, ...obj }));

  const value = { appState, updateAppState, updateAppStateLocal, getUserData };
  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  );
}

function useAppStateProvider() {
  const context = React.useContext(AppStateContext);
  if (context === undefined) {
    throw new Error("appContext must be used within a AppStateProvider");
  }
  return context;
}

export { AppStateProvider, AppStateContext, useAppStateProvider };
