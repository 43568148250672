import React, { useState, useEffect } from "react";
import { PageLayout } from "../../components/Home/PageLayout";
import { useAppStateProvider } from "../../contextProviders/AppStateProvider";
import { useInfoProvider } from "../../contextProviders/InfoProvider";
import { useAppLaunchProvider } from "../../contextProviders/AppLaunchProvider";
import handleWindowPopUp from "../../util/handleWindowPopup";
import { userActions } from "../../contextProviders/constant";
import EncourageSetup from "../../components/SetupModal/index";
import createTranslator from "../../util/translationHelper";
import Notification from "@moneris-portal/notification";
import { ToastMessage, addToastMessage } from "@moneris-portal/toastmessage";
import messages from "./translations/messages";
import { useHistory } from "react-router-dom";
import Loader from "@moneris-portal/loader";
import getToken from "../../util/tokenGenerator";
import { sortObjectByValue, hashSha256 } from "../../util/helper";
import { useMsal } from "@azure/msal-react";
import { env } from "../../cfg/env";
import { partnerPortalUrls, md2Urls } from "./constant";
import { b2cPolicies } from "../../authConfig";

import "./index.scss";

function Home({ lang }) {
  const { instance, inProgress, accounts } = useMsal();
  const homeAccountId = accounts[0]?.homeAccountId ?? "";
  const logoutRequest = {
    account: instance.getAccountByHomeId(homeAccountId),
  };
  const history = useHistory();
  const searchParams = new URLSearchParams(window?.location?.search) ?? "";
  const logoutAction = searchParams.get("logout");
  const appCodeToLaunch = searchParams.get("app_code")?.toLowerCase() ?? "";
  const { appState = {}, updateAppStateLocal } = useAppStateProvider();
  const { updateAppLaunchState } = useAppLaunchProvider();
  const { dispatch } = useInfoProvider();
  const {
    apps = [{}],
    profile = {},
    isLoading = false,
    isMd2Lite = false,
    isUPApp = false,
    isPopUpBlocked,
    isObSetup = false,
    hasPartnerRole = null,
    hasSharedCashRole = null,
  } = appState;

  const { app_code = "" } = apps[0] ?? {};
  // temporary disabling encourgement step
  const isEncourgementStepEnabled =
    !(apps.length === 1 && app_code === "DE") && false;
  const [showStep, handleShowStep] = useState(isEncourgementStepEnabled);
  const [isError, setError] = useState(false);

  const { firstName = "Username", lastName = "", userEmail } = profile;
  const { firstSignIn = false } = profile;
  const translate = createTranslator(messages, lang);
  // const testVar = window.localStorage?.getItem("testVar") ?? "false";

  const handleAuditLog = (payload) => {
    dispatch({
      type: userActions.auditLog,
      body: { ...payload },
    });
  };

  const handleMfaOption = (payload) => {
    dispatch({
      type: userActions.disableMFA,
      body: { ...payload },
    });
  };

  useEffect(async () => {
    if (hasPartnerRole && logoutAction !== "true") {
      const token = await getToken(instance, inProgress, accounts, setError);
      if (token) {
        handleAuditLog({ appCode: "MPP", success: true });
        const envType = env?.appEnv ?? "PROD";
        const partnerUrl = partnerPortalUrls[envType];
        const isRedirected = handleWindowPopUp(
          `${partnerUrl
            ?.toString()
            ?.replace("{lang}", lang)}?token=${token}&sso=1${
            envType === "PROD" ? "" : "&type=partner"
          }`,
          "_self"
        );
        if (!isRedirected) {
          updateAppStateLocal({ isPopUpBlocked: true, isLoading: false });
          window.sessionStorage.setItem("isPopUpBlocked", true);
        }
      } else {
        handleAuditLog({ appCode: "MPP", success: false });
        updateAppStateLocal({ isLoading: false });
      }
    }
  }, [hasPartnerRole]);

  useEffect(async () => {
    if (hasSharedCashRole && logoutAction !== "true") {
      const token = await getToken(instance, inProgress, accounts, setError);
      if (token) {
        handleAuditLog({ appCode: "MD2", success: true });
        const md2Url = md2Urls(lang)[env?.appEnv ?? "PROD"];
        const isRedirected = handleWindowPopUp(
          `${md2Url?.toString()}?token=${token}&sso=1`,
          "_self"
        );
        if (!isRedirected) {
          updateAppStateLocal({ isPopUpBlocked: true, isLoading: false });
          window.sessionStorage.setItem("isPopUpBlocked", true);
        }
      } else {
        handleAuditLog({ appCode: "MD2", success: false });
        updateAppStateLocal({ isLoading: false });
      }
    }
  }, [hasSharedCashRole]);

  useEffect(
    function () {
      if (userEmail) {
        const hashPromise = hashSha256(userEmail);
        hashPromise
          .then((hashedEmail) => {
            // Data Layer for Google Tag Manager
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              hashed_email: hashedEmail,
            });
            // End Data Layer for Google Tag Manager
            /*------------------------------------*/
            //  Google Tag Manager
            (function (w, d, s, l, i) {
              w[l] = w[l] || [];
              w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != "dataLayer" ? "&l=" + l : "";
              j.async = true;
              j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, "script", "dataLayer", "GTM-ML3Z37Q");
            //  End Google Tag Manager
          })
          .catch((error) => console.log(error));
      }
    },
    [userEmail]
  );

  useEffect(async () => {
    const { app_code = "", login_endpoint = "" } = apps[0] ?? {};
    if (logoutAction !== "true") {
      if (apps?.length === 1 && app_code === "DE") {
        updateAppStateLocal({ isLoading: true });
        const token = await getToken(instance, inProgress, accounts, setError);
        const endpoint = login_endpoint?.toString()?.split("?")[0] ?? "";
        if (token && endpoint) {
          handleAuditLog({ appCode: "DE", success: true });
          const isRedirected = handleWindowPopUp(
            `${endpoint}?token=${token}&sso=1&lang=${lang}`,
            "_self"
          );
          if (!isRedirected) {
            updateAppStateLocal({ isPopUpBlocked: true, isLoading: false });
            window.sessionStorage.setItem("isPopUpBlocked", true);
          }
        } else {
          handleAuditLog({ appCode: "DE", success: false });
          updateAppStateLocal({ isLoading: false });
        }
      }
      // MS-503. disabling second landing page
      else {
        apps.forEach(async (item) => {
          const { app_code = "", login_endpoint } = item;
          if (app_code?.toString().toLowerCase() === "md2" && isObSetup) {
            updateAppStateLocal({ isLoading: true });
            const token = await getToken(
              instance,
              inProgress,
              accounts,
              setError
            );
            const endpoint = login_endpoint?.toString()?.split("?")[0] ?? "";
            if (token && endpoint) {
              handleAuditLog({ appCode: "MD2", success: true });
              const isRedirected = handleWindowPopUp(
                `${endpoint
                  ?.toString()
                  ?.replace("{lang}", lang)}?token=${token}&sso=1`,
                "_self"
              );
              if (!isRedirected) {
                updateAppStateLocal({ isPopUpBlocked: true, isLoading: false });
                window.sessionStorage.setItem("isPopUpBlocked", true);
              }
            } else {
              handleAuditLog({ appCode: "MD2", success: false });
              updateAppStateLocal({ isLoading: false });
            }
          }
        });
      }
    }
  }, [apps, isObSetup]);

  useEffect(async () => {
    let url = env?.sso?.mdliteUser?.redirect ?? "";
    if (isMd2Lite && logoutAction !== "true") {
      updateAppStateLocal({ isLoading: true });
      const token = await getToken(instance, inProgress, accounts, setError);
      if (token && url) {
        handleAuditLog({ appCode: "MD2", success: true });
        const isRedirected = handleWindowPopUp(
          `${url?.toString()?.replace("{lang}", lang)}?token=${token}&sso=1`,
          "_self"
        );
        if (!isRedirected) {
          updateAppStateLocal({ isPopUpBlocked: true, isLoading: false });
          window.sessionStorage.setItem("isPopUpBlocked", true);
        }
      } else {
        handleAuditLog({ appCode: "MD2", success: false });
        updateAppStateLocal({ isLoading: false });
      }
    }
  }, [isMd2Lite]);

  /**
   * Used for UP, when the UP app is in the user's list of apps, it'll be redirected to the UP portal after login
   */
  useEffect(async () => {
    const hasMd2Tile = apps.reduce((prevVal, currentVal) => {
      const { app_code = "" } = currentVal;
      return prevVal || app_code?.toString().toLowerCase() === "md2";
    }, false);

    // redirect is not eligible for follwing conditions
    const isUpRedirectNotEligible =
      isMd2Lite ||
      hasPartnerRole ||
      hasSharedCashRole ||
      (apps.length === 1 && app_code?.toString().toLowerCase() === "de") ||
      (isObSetup && hasMd2Tile) ||
      logoutAction === "true";

    if (isUPApp && !isUpRedirectNotEligible) {
      let url = isUPApp.login_endpoint;

      if (url) {
        handleAuditLog({ appCode: "UP", success: true });
        const isRedirected = handleWindowPopUp(url?.toString(), "_self");
        if (!isRedirected) {
          updateAppStateLocal({ isPopUpBlocked: true, isLoading: false });
          window.sessionStorage.setItem("isPopUpBlocked", true);
        }
      }
    } else {
      // remove loading if won't redirect
      updateAppStateLocal({ isLoading: false });
    }
  }, [isUPApp]);

  useEffect(() => {
    if (isPopUpBlocked) {
      addToastMessage({
        variant: "danger",
        message:
          lang === "en"
            ? `Enable your browser's pop-ups to access the content.`
            : `Autorisez les fenêtres contextuelles dans votre navigateur pour accéder au contenu.`,
      });
      window.sessionStorage.setItem("isPopUpBlocked", false);
      updateAppStateLocal({ isPopUpBlocked: false });
    }
  }, [isPopUpBlocked]);

  //logout user incase application state is not fully authenticated
  useEffect(() => {
    if (isError) instance.logoutRedirect({ postLogoutRedirectUri: "/" });
  }, [isError]);

  useEffect(() => {
    if (appCodeToLaunch === "mogo")
      updateAppLaunchState({ type: userActions.authorizeClient });
  }, [appCodeToLaunch]);

  useEffect(() => {
    // This variable calculate all apps max attempt flags value and return true if any is true
    const ismaxAttemptReached = apps.reduce((prevVal, currentObj) => {
      const { failed_attempts_exceeded = false } = currentObj;
      return prevVal || failed_attempts_exceeded;
    }, false);
    if (ismaxAttemptReached) {
      handleLogout();
    }
  }, [apps]);

  useEffect(() => {
    const payload = { enable: false, email: userEmail };
    handleMfaOption(payload);
  }, [userEmail]);

  const handleLogout = () => {
    instance.logoutRedirect(logoutRequest);
  };

  const handleDoItLater = () => {
    dispatch({
      type: userActions.updateUserInfo,
      body: { firstSignIn: false },
    });
    handleShowStep(false);
  };

  const handleActivateTile = (appCode = "") => {
    if (appCode === "MD2")
      instance.loginRedirect(b2cPolicies.authorities.activateTileMd2);
    else if (appCode === "MOGO")
      instance.loginRedirect(b2cPolicies.authorities.activateTileMgo);
    return null;
  };

  return (
    <div id="home_container">
      {isError && (
        <div style={{ maxWidth: "27rem" }}>
          <Notification variant="danger">
            {translate("invalid_message")}
          </Notification>
        </div>
      )}
      {!isLoading && (
        <>
          {firstSignIn && showStep && (
            <EncourageSetup
              history={history}
              handleShowStep={handleDoItLater}
              lang={lang}
            />
          )}
          <PageLayout
            apps={sortObjectByValue(apps)}
            username={`${firstName} ${lastName}`}
            handleAuditLog={handleAuditLog}
            updateAppLaunchState={(action) => {
              updateAppLaunchState(action);
            }}
            handleActivateTile={handleActivateTile}
          />
        </>
      )}
      {isLoading && <Loader lang={lang} />}
      <ToastMessage
        maximumToasts={0}
        duration={7000}
        position="top-center"
        style={{ top: "4rem" }}
      />
    </div>
  );
}

export default Home;
