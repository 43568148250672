import { env } from "../cfg/env";

const authClientDomain = env.sso.authClientDomain;

const userActions = {
  fetchUserInfo: "fetchUserInfo",
  updateUserInfo: "updateUserInfo",
  sendMfaCode: "sendMfaCode",
  sendTotpInfo: "sendTotpInfo",
  verifyMfaCode: "verifyMfaCode",
  getAppIds: "getAppIds",
  totpInitCall: "totpInitCall",
  verifyTotpCode: "verifyTotpCode",
  resetTotp: "resetTotp",
  auditLog: "auditLog",
  isMd2Lite: "isMd2Lite",
  isObSetup: "isObSetup",
  authorizeClient: "authorizeClient",
  authToken: "authToken",
  middlewareLogout: "middlewareLogout",
  ssoSessionCheck: "ssoSessionCheck",
  disableMFA:"disableMFA"
};

const funcName = `${env.sso.functionDomain}`;
const authTokenDomain = `${env.sso.authTokenDomain}`;

const apiList = {
  [userActions.fetchUserInfo]: `https://${funcName}/auth/api/b2c-user-info`,
  [userActions.updateUserInfo]: `https://${funcName}/auth/api/b2c-user-info-update`,
  [userActions.sendMfaCode]: `https://${funcName}/auth/api/b2c-mfa-code-send`,
  [userActions.sendTotpInfo]: `https://${funcName}/auth/api/b2c-mfa-totp-setup`,
  [userActions.verifyTotpCode]: `https://${funcName}/auth/api/b2c-mfa-totp-verify`,
  [userActions.totpInitCall]: `https://${funcName}/auth/api/b2c-mfa-totp-init`,
  [userActions.verifyMfaCode]: `https://${funcName}/auth/api/b2c-mfa-code-verify`,
  [userActions.resetTotp]: `https://${funcName}/auth/api/b2c-mfa-totp-reset`,
  [userActions.auditLog]: `https://${funcName}/auth/api/b2c-log-audit-event`,
  [userActions.isMd2Lite]: `https://${funcName}/auth/api/md2-is-applicant`,
  [userActions.isObSetup]: `https://${funcName}/auth/api/md2-redirect-eligibility`,
  [userActions.ssoSessionCheck]: `https://${funcName}/auth/portal/check`,
  [userActions.authorizeClient]: `https://${authClientDomain}/B2C_1A_SIGNUP_SIGNIN/oauth2/v2.0/authorize`,
  [userActions.authToken]: `https://${funcName}/auth/token`,
  [userActions.middlewareLogout]: `https://${funcName}/auth/logout`,
  [userActions.disableMFA]: `https://${funcName}/auth/api/b2c-user-disable-mfa`,
};

const clinetIds = {
  mogo: {
    dev: "77192686-ed8b-47b9-9682-066ff527ca74",
    qa: "02042f00-525c-4336-b232-e4a10d333ffb",
    prod: "c0e17c8f-73d9-4d5f-b308-424fee35143d",
  },
};

const methodType = {
  get: "GET",
  post: "POST",
  patch: "PATCH",
  delete: "DELETE",
};

export { userActions, apiList, methodType, clinetIds };
